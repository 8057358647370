// This is the main entry point to the web-client application.
// It is added at the end of index.template.html.
//
// This adds the <script> tag to load app.js, which bootstraps
// the AngularJS app and configures the UI router.

// The markup in appContent.html is parsed by AngularJS in the context of the
// MainCtrl controller (due to the ng-controller attribute on <html>).
// Since that controller does not declare an isolate scope, all of the vars
// in the appContent markup are referencing root scope.
//
const supportedBrowsers = require('./supportedBrowsers');

if (supportedBrowsers.test(navigator.userAgent)) {
    var appContent = require('../appContent.html');
    var app = document.currentScript.getAttribute('app');
    var vendor = document.currentScript.getAttribute('vendor');
    document.write(`${appContent}<script src="${vendor}"></script><script src="${app}"></script>`);
} else {
    var unsupported = require('../unsupported.html');
    document.write(unsupported);
}
